import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { Token } from '../types/token';

@Injectable()
export class HingesService {
  constructor(
    private http: HttpClient
  ) { }

getHingesOverlay(doorTypeId: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['doorTypeId'] = doorTypeId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/hinges/overlays`, requestOptiosn);
  }

  getHingeFrameless(doorTypeId: string): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['doorTypeId'] = doorTypeId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/hinges/gethingeframeless`, requestOptiosn);
  }


  getHingesOverlayByApartmentComplex(apartmentComplexId: number, closeTypeId: number ) {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentComplexId'] = apartmentComplexId;
    searchParams['closeTypeId'] = closeTypeId;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/hinges/overlaysByApartmentComplexId`, requestOptiosn);
  }

  getHingesType(id: number): Observable<any> {
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['hingeOverlayID'] = id.toString();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = {headers, params: searchParams};
    return this.http.get(`${environment.api}api/hinges/closeTypes`, requestOptiosn);
  }

  getCloseType(): Observable<any> {
    // tslint:disable-next-line:prefer-const
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    
    return this.http.get(`${environment.api}api/hinges/closetypesList`, {headers});
  }

  getHingesOffset(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/hinges/offsets`, {headers});
  }  
}
