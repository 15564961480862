<div class="loader" [ngClass]="middle? 'middle': ''" *ngIf="loading">
  <div
    *ngIf="show === 0"
    class="spinner"
    [style.width.px]="radiusSize"
    [style.height.px]="radiusSize"
    [style.border-width.px]="borderSize">
  </div>
  <i *ngIf="show === 1" class="icons success material-icons" [style.font-size.px]="radiusSize">&#xE5CA;</i>
  <i *ngIf="show === 2" class="icons fail material-icons" [style.font-size.px]="radiusSize">&#xE5CD;</i>
  <p class="message" [style.font-size.px]="messageSize">{{message}}</p>
</div>
