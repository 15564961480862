var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { SignalrService } from './signalr.service';
import { filter } from 'rxjs/operators';
import { OrderReviewService } from './order-review.service';
import { PackagePlanningService } from './package-planning.service';
import { Status } from '../types/package-plan-status-message';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "./signalr.service";
import * as i2 from "./package-planning.service";
import * as i3 from "./order-review.service";
import * as i4 from "ngx-toastr";
var RECEIVED_MESSAGE_TIMEOUT = 10000;
var COMPLETED_MESSAGE_TIMEOUT = 14000;
var SIGNALR_ERROR_MESSAGE = 'The package planning notifications may not be available.';
var TOAST_POSITION = 'toast-bottom-right';
var AddToCartService = /** @class */ (function () {
    function AddToCartService(signalrService, packagePlanningService, orderReviewService, toastr) {
        var _this = this;
        this.signalrService = signalrService;
        this.packagePlanningService = packagePlanningService;
        this.orderReviewService = orderReviewService;
        this.toastr = toastr;
        this.signalrError = false;
        this.errorSubscription = this.signalrService.error$.subscribe(function (error) {
            _this.signalrError = true;
            console.warn(error);
        }, function (error) {
            _this.signalrError = true;
            console.error('errors$ error', error);
        });
        // Wire up a handler for the starting$ observable to log the
        //  success/fail result
        //
        this.startingSubscription = this.signalrService.starting$.subscribe(function () {
            console.log('Signalr service has been started');
        }, function () {
            console.warn('Signalr service failed to start!');
        });
        try {
            if (!this.signalrService.isConnected()) {
                console.log('Add To Cart Action: signalrService.start');
                this.signalrService.connect();
            }
        }
        catch (err) {
            this.signalrError = true;
            console.error('Error starting the signalr connection to a hub.', err);
        }
    }
    AddToCartService.prototype.signalrConnectionIsActive = function () {
        console.log('Action: signalrConnectionIsActive');
        return !this.signalrError && this.signalrService.isConnected();
    };
    AddToCartService.prototype.canExecutePackagePlanProcess = function (apartmentId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Action: canExecutePackagePlanProcess');
                        return [4 /*yield*/, this.orderReviewService.validateOrder(apartmentId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AddToCartService.prototype.addToCart = function (apartmentId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var receivedMsgTimeoutId_1, completedMsgTimeoutId_1, statusMessage_1, item_1, packagePlanningSubscription_1, signalrServiceSubscription_1, _callback_1, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        callback = callback || (function () { return console.log('Executing default addToCart callback.'); });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.signalrConnectionIsActive()) {
                            this.toastr.warning(SIGNALR_ERROR_MESSAGE, '', { positionClass: TOAST_POSITION });
                        }
                        return [4 /*yield*/, this.canExecutePackagePlanProcess(apartmentId)];
                    case 2:
                        if (_a.sent()) {
                            item_1 = {
                                apartmentId: apartmentId
                            };
                            console.log('Action: packagePlanningService.process');
                            packagePlanningSubscription_1 = this.packagePlanningService.process(item_1)
                                .subscribe(function (_) { return console.log('Successful package planning process request.'); }, function (err) { return console.error('Error with the package planning process request:', err); });
                            signalrServiceSubscription_1 = this.signalrService.statusMessage
                                .pipe(filter(function (msg) { return item_1.apartmentId === msg.apartmentId; }))
                                .subscribe(function (msg) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    statusMessage_1 = msg;
                                    console.log('Status message:', msg);
                                    if (msg.currentStatus === Status.Completed || msg.currentStatus === Status.Error) {
                                        console.log('Package planning process finished. Current status:', msg.currentStatus);
                                        return [2 /*return*/, _callback_1()];
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                            completedMsgTimeoutId_1 = setTimeout(function () {
                                if (statusMessage_1 && statusMessage_1.currentStatus !== Status.Completed) {
                                    console.log('Package planning completed message timeout.');
                                    return _callback_1();
                                }
                            }, COMPLETED_MESSAGE_TIMEOUT);
                            receivedMsgTimeoutId_1 = setTimeout(function () {
                                if (statusMessage_1 == null) {
                                    console.log('Package planning received message timeout.');
                                    return _callback_1();
                                }
                            }, RECEIVED_MESSAGE_TIMEOUT);
                            _callback_1 = function () {
                                clearTimeout(receivedMsgTimeoutId_1);
                                clearTimeout(completedMsgTimeoutId_1);
                                signalrServiceSubscription_1.unsubscribe();
                                packagePlanningSubscription_1.unsubscribe();
                                return callback && callback();
                            };
                        }
                        else {
                            return [2 /*return*/, callback && callback()];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error('Error in addToCart action:', e_1);
                        return [2 /*return*/, callback && callback()];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AddToCartService.prototype.ngOnDestroy = function () {
        this.errorSubscription.unsubscribe();
        this.startingSubscription.unsubscribe();
    };
    AddToCartService.ngInjectableDef = i0.defineInjectable({ factory: function AddToCartService_Factory() { return new AddToCartService(i0.inject(i1.SignalrService), i0.inject(i2.PackagePlanningService), i0.inject(i3.OrderReviewService), i0.inject(i4.ToastrService)); }, token: AddToCartService, providedIn: "root" });
    return AddToCartService;
}());
export { AddToCartService };
