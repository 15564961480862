<div class="modal-body">
  <div class="container">
    <h2 class="title">Past Due Invoices</h2>
    <h2 class="subtitle" [innerHTML]="title"></h2>
    <div class="table-content">
      <table>
        <thead>
          <th>Invoice #</th>
          <th>Due Date</th>
          <th>PO</th>
          <th>Apartment(s)</th>
          <th>Location</th>
          <th>Remaining Amount</th>
        </thead>
        <tbody>
          <tr *ngFor="let row of jsonLedge;">
            <td>
              <div class="invoice-column"><a href="javascript:void(0)" (click)="downloadInvoice(row)">{{ row.DocNumber
                  || row.docNumber }}</a></div>
            </td>
            <td>
              <div class="invoice-date-column">{{ row.PastDueDate || row.pastDueDate }}</div>
            </td>
            <td>
              <div class="ellipsis large-content po-column">
                {{ row.PO || row.po}}
                <div *ngIf="this.shouldDisplayToolTip(row.PO || row.pO, 14)" class="tool-tip">{{ row.PO || row.pO }}
                </div>
              </div>
            </td>
            <td>
              <div class="ellipsis large-content apartment-column">
                {{ row.Apartment || row.apartment }}
                <div *ngIf="this.shouldDisplayToolTip(row.Apartment || row.apartment, 24)" class="tool-tip">{{
                  row.Apartment || row.apartment }}</div>
              </div>
            </td>
            <td>
              <div class="ellipsis large-content complex-column">
                {{ row.Complex || row.complex }}
                <div *ngIf="this.shouldDisplayToolTip(row.Complex || row.complex, 23)" class="tool-tip">{{ row.Complex
                  || row.complex }}</div>
              </div>
            </td>
            <td class="remaining">
              <div>${{ row.Remaining_Amt_LCY || row.remaining_Amt_LCY | number:'1.2'}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="total">
  <span>Total Balance Past Due ${{total | number:'1.2' }}</span>
</div>