export class MeasurementForm {
  constructor(
    public partType: string,
    public width: string,
    public height: string,
    public quantity: number,
  ) {}
}

export class MeasurementFormResult {
  constructor(
    public imageUrl: string,
    public measurementFormList: MeasurementForm[],
  )
  {}
}

export class MeasurementFormPost {
  constructor(
    public apartmentId: number,
    public measurementFormList: Array<MeasurementForm>,
  )
  {}
}
