/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/modal/modal.directive";
import * as i3 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i4 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { childModal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 8, "div", [["aria-hidden", "true"], ["aria-labelledby", "mySmallModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "onHidden"], [null, "onShown"], [null, "onHide"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("onHidden" === en)) {
        var pd_2 = (_co.emitHidden($event) !== false);
        ad = (pd_2 && ad);
    } if (("onShown" === en)) {
        var pd_3 = (_co.emitOpened($event) !== false);
        ad = (pd_3 && ad);
    } if (("onHide" === en)) {
        var pd_4 = (_co.emitHide($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4], ["childModal", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, { onShown: "onShown", onHide: "onHide", onHidden: "onHidden" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-frame modal-dialog center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "modal-content center"]], [[4, "height", null], [4, "width", null], [4, "margin-top", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideChildModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHeighter ? _co.isHeighter : "573px"); var currVal_1 = (_co.isNarrow ? _co.isNarrow : "920px"); var currVal_2 = (_co.isHeighter ? "0px" : "100px"); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i4.ModalComponent, [], null, null)], null, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i4.ModalComponent, View_ModalComponent_Host_0, { isHeighter: "isHeighter", isNarrow: "isNarrow", centered: "centered" }, { closed: "closed", opened: "opened" }, [".modal-body"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
