var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FedexService } from 'app/shared/services/fedex.service';
import { BlockUI } from 'ng-block-ui';
var ModalFedexComponent = /** @class */ (function () {
    function ModalFedexComponent(dialogRef, data, fedexService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fedexService = fedexService;
        this.validationOptions = [];
    }
    ModalFedexComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.onValidAddressByFedex()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalFedexComponent.prototype.onValidAddressByFedex = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.blockUI.start('Loading matches...');
                        return [4 /*yield*/, this.fedexService.getAddress(this.data.addressToValidate)
                                .toPromise()
                                .then(function (result) {
                                _this.fedexValidation = result;
                                if (_this.fedexValidation && _this.fedexValidation.warningMessage) {
                                    _this.validationOptions.push({ text: 'Keep my data', select: false, isSuggest: false });
                                    return;
                                }
                                if (_this.fedexValidation && _this.fedexValidation.streetLines) {
                                    _this.data.addressSuggestion = _this.buildAddress();
                                    var suggestAddress = _this.fedexValidation.streetLines.length > 1 ?
                                        _this.fedexValidation.streetLines[0] + ",\n            " + _this.fedexValidation.streetLines[1] + ",\n            " + _this.fedexValidation.city + ",\n            " + _this.fedexValidation.stateOrProvince + ",\n            " + _this.fedexValidation.postalCode :
                                        _this.fedexValidation.streetLines[0] + ",\n            " + _this.fedexValidation.city + ",\n            " + _this.fedexValidation.stateOrProvince + ",\n            " + _this.fedexValidation.postalCode;
                                    _this.validationOptions.push({ text: suggestAddress, select: false, isSuggest: true });
                                    _this.validationOptions.push({ text: 'Keep my data', select: false, isSuggest: false });
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.blockUI.stop();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalFedexComponent.prototype.onValidationSelect = function (option) {
        this.validationOptions.map(function (opt) { return opt.select = (opt === option); });
    };
    ModalFedexComponent.prototype.onKeepEdit = function () {
        this.data.modalState = 'keep-edit';
        this.dialogRef.close(this.data);
    };
    ModalFedexComponent.prototype.onAllowContinue = function () {
        return !(this.validationOptions.filter(function (f) { return f.select === true; }).length === 0);
    };
    ModalFedexComponent.prototype.onSelectOption = function () {
        var optionSelect = this.validationOptions.find(function (f) { return f.select === true; });
        if (optionSelect !== undefined) {
            if (optionSelect.isSuggest) {
                this.data.modalState = 'suggested-address';
                this.data.addressSelection = this.buildAddress();
            }
            else {
                this.data.modalState = 'keep-address';
            }
            this.dialogRef.close(this.data);
        }
    };
    ModalFedexComponent.prototype.buildAddress = function () {
        var _this = this;
        var stateFind = this.data.stateList.find(function (f) { return f.abbreviation.toUpperCase() === _this.fedexValidation.stateOrProvince.toUpperCase(); });
        return {
            addressLine1: this.fedexValidation.streetLines[0],
            addressLine2: this.fedexValidation.streetLines.length > 1 ? this.fedexValidation.streetLines[1] : '',
            addressCity: this.fedexValidation.city,
            addressStateID: stateFind.id,
            addressStateName: stateFind.name,
            addressZipCode: this.fedexValidation.postalCode.toString()
        };
    };
    __decorate([
        BlockUI(),
        __metadata("design:type", Object)
    ], ModalFedexComponent.prototype, "blockUI", void 0);
    return ModalFedexComponent;
}());
export { ModalFedexComponent };
