/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-fedex.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-fedex.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../shared/services/fedex.service";
var styles_ModalFedexComponent = [i0.styles];
var RenderType_ModalFedexComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalFedexComponent, data: {} });
export { RenderType_ModalFedexComponent as RenderType_ModalFedexComponent };
function View_ModalFedexComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fedexValidation.warningMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalFedexComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "list-group"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "list-group-item list-group-item-action mb-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onValidationSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_1 = "list-group-item list-group-item-action mb-2"; var currVal_2 = (_v.context.$implicit.select ? "active" : ""); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = ("optionFedEx" + (_v.context.index + 1)); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_3); }); }
export function View_ModalFedexComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suggested standardized address"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "alert alert-light text-center"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFedexComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFedexComponent_2)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "row justify-content-end mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "col-6 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn primary mr-2"], ["id", "btnKeepEditing"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onKeepEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Keep Editing"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn primary"], ["id", "btnContinue"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectOption() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fedexValidation && _co.fedexValidation.warningMessage); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.validationOptions; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.onAllowContinue(); _ck(_v, 15, 0, currVal_2); }); }
export function View_ModalFedexComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-fedex", [], null, null, null, View_ModalFedexComponent_0, RenderType_ModalFedexComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalFedexComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA, i5.FedexService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalFedexComponentNgFactory = i1.ɵccf("app-modal-fedex", i3.ModalFedexComponent, View_ModalFedexComponent_Host_0, {}, {}, []);
export { ModalFedexComponentNgFactory as ModalFedexComponentNgFactory };
