var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
var LoginService = /** @class */ (function () {
    function LoginService(http, router, toastr) {
        this.http = http;
        this.router = router;
        this.toastr = toastr;
        this.params = new URLSearchParams();
        this.header = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Charset': 'utf-8'
        });
        this.options = { headers: this.header };
        this.hasLedgeEntries = new BehaviorSubject(false);
        this.accessCustomerList = new BehaviorSubject([]);
        this.authenticate = new BehaviorSubject(false);
    }
    LoginService.prototype.signIn = function (credentials) {
        var _this = this;
        for (var key in credentials) {
            if (credentials.hasOwnProperty(key)) {
                this.params.set(key, credentials[key]);
            }
        }
        return this.http.post(environment.api + "token", this.params.toString(), this.options)
            .map(function (token) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentToken = token;
                        localStorage.setItem('token', JSON.stringify(token));
                        // Verify if is a multiple user or CRS user
                        return [4 /*yield*/, this.userHasMultipleAccounts()];
                    case 1:
                        // Verify if is a multiple user or CRS user
                        _a.sent();
                        if (!(this.accessCustomerList.value.length === 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.userSalePersonHasMultipleAccounts()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (this.accessCustomerList.value.length === 0) {
                            this.authenticate.next(true);
                            this.getLedgerEntries();
                        }
                        return [2 /*return*/, token];
                }
            });
        }); });
    };
    LoginService.prototype.logOut = function () {
        var _this = this;
        localStorage.removeItem('token');
        this.authenticate.next(false);
        this.toastr.error('please re-sign in', 'Your session has expired', {
            easeTime: 3000,
            progressAnimation: 'increasing',
            positionClass: 'toast-bottom-left'
        }).onShown.subscribe(function () {
            _this.router.navigate(['/login']);
        });
    };
    LoginService.prototype.cleanShoppingCart = function () {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        var searchParams = new Object();
        var requestOptions = { headers: headers, params: searchParams };
        return this.http.delete(environment.api + "api/orders/deletecart", requestOptions).toPromise();
    };
    LoginService.prototype.getToken = function () {
        var localStorageToken = JSON.parse(localStorage.getItem('token'));
        if (this.currentToken == null) {
            this.currentToken = JSON.parse(localStorage.getItem('token'));
        }
        if (this.currentToken != null && localStorageToken != null &&
            this.currentToken.token_type + " " + this.currentToken.access_token !=
                JSON.parse(localStorage.getItem('token')).token_type + " " + JSON.parse(localStorage.getItem('token')).access_token) {
            window.location.replace(window.location.origin + '/dashboard');
        }
        else {
            return JSON.parse(localStorage.getItem('token')) || false;
        }
    };
    LoginService.prototype.getAccount = function () {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/account", { headers: headers })
            .map(function () { return true; }).catch(function (err) {
            return Observable.of(false);
        });
    };
    LoginService.prototype.getLedgerEntries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.http.get(environment.api + "api/orders/dueleadgeEntries", { headers: headers })
                                .toPromise()
                                .then(function (ledgers) {
                                _this.customerLedge = ledgers;
                                _this.hasLedgeEntries.next(_this.customerLedge.length > 0);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.userHasMultipleAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.http.get(environment.api + "api/account/checkmultipleaccounts", { headers: headers })
                                .toPromise()
                                .then(function (accounts) {
                                _this.multipleAccounts = accounts;
                                _this.accessCustomerList.next(accounts);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.userSalePersonHasMultipleAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.http.get(environment.api + "api/account/getAccountsBySalesPerson", { headers: headers })
                                .toPromise()
                                .then(function (accounts) {
                                _this.multipleAccounts = accounts;
                                _this.accessCustomerList.next(accounts);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.listMultipleAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        _a = this;
                        return [4 /*yield*/, this.http.get(environment.api + "api/account/checkmultipleaccounts", { headers: headers }).toPromise()];
                    case 1: return [2 /*return*/, _a.multipleAccounts = _b.sent()];
                }
            });
        });
    };
    LoginService.prototype.setAccount = function (customerId) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.http.post(environment.api + "api/account/setaccount?customerID=" + customerId, { headers: headers })
                                .toPromise()
                                .then(function (updateAccount) {
                                var currentToken = JSON.parse(localStorage.getItem("token"));
                                currentToken.companyname = updateAccount.companyname;
                                currentToken.customernumber = updateAccount.customernumber;
                                currentToken.role = updateAccount.role;
                                currentToken.isCSR = updateAccount.isCSR;
                                localStorage.setItem("token", JSON.stringify(currentToken));
                                _this.authenticate.next(true);
                                _this.getLedgerEntries();
                                console.log('currentToken', currentToken);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.verifyPass = function (state, pass) {
        var str;
        switch (state) {
            case 'pass-number':
                str = /^((?=.*\d)|(?=.*\W+))/;
                break;
            case 'length':
                str = /^(?=^.{8,}$)/;
                break;
            case 'low-uper':
                str = /^(?![.\n])(?=.*[A-Z])(?=.*[a-z])/;
                break;
            default:
                break;
        }
        return !str.test(pass);
    };
    LoginService.prototype.recordTermsAndCondition = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headers = new HttpHeaders();
                        headers.append('Content-Type', 'application/json');
                        return [4 /*yield*/, this.http.post(environment.api + "api/account/recordtermsandcondition", { headers: headers }).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.customerLogin = function (customerLogin) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = new HttpHeaders();
                headers.append('Content-Type', 'application/json');
                return [2 /*return*/, this.http.post(environment.api + "api/account/customerlogin", customerLogin, { headers: headers }).toPromise()];
            });
        });
    };
    return LoginService;
}());
export { LoginService };
