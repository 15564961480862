<div class="container-fluid">
  <div class="text-center">
    <h4>Suggested standardized address</h4>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="alert alert-light text-center" role="alert">
        <p class="text-danger" *ngIf="fedexValidation && fedexValidation.warningMessage">{{ fedexValidation.warningMessage }}</p>
        <div class="list-group" [id]="'optionFedEx'+(i+1)" *ngFor="let option of validationOptions;index as i">
          <a class="list-group-item list-group-item-action mb-2"
          [ngClass]="option.select ? 'active' : ''" (click)="onValidationSelect(option)">{{ option.text }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end mt-2">
    <div class="col-6 text-right">
      <button type="button" id="btnKeepEditing" class="btn primary mr-2" (click)="onKeepEdit()">Keep Editing</button>
      <button type="button" id="btnContinue" class="btn primary" [disabled]="!onAllowContinue()" (click)="onSelectOption()">Continue</button>
    </div>
  </div>
</div>
