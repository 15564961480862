import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpHeaders, HttpClient } from '@angular/common/http';

import { AparmentComplexInformation } from '../types/aparment-complex-info';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from 'rxjs/Observable';
import { OrderProceed } from '../types/aparment-complex-info';
import { Token } from '../types/token';
import { environment } from '../../../environments/environment';
import { LocationDetail } from '../types/location';

@Injectable()
export class AparmentComplexInformationService {
  public savingAparment = new BehaviorSubject(true);
  public sectionHandler = new BehaviorSubject(0);
  public apartmentAddress = new BehaviorSubject({});

  constructor(
    private http: HttpClient
  ) { }

  getStates(): Observable<any> {
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/states`, {headers: headers});
  }

  saveApartmentComplexInformation(data: OrderProceed): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/complexes`, data, {headers: headers});
  }

  getApartmentComplexInformationById(token: Token, id: number) {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/complexes/${id}/update`, {headers: headers});
  }

  getApartmentShippingAddress(aptComplexID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/complexes/${aptComplexID}/shippingaddress`, {headers: headers});
  }

  saveNewLocation(data: LocationDetail): Observable<number> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post<number>(`${environment.api}api/complexes`, data, { headers: headers });
  }

  updateApartmentComplexInformation(apartmentComplexInfo: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/complexes`, {...apartmentComplexInfo }, {headers: headers});
  }

  updateLocationInformation(apartmentComplexInfo: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/complexes/updatelocationinformation`, {...apartmentComplexInfo }, {headers: headers});
  }

  updateShippingAddressLocation(apartmentComplexInfo: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/complexes/updateshippingaddresslocation`, {...apartmentComplexInfo }, {headers: headers});
  }

  getComplexShippingAddress( id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/complexes/${id}/shippingaddress`, {headers: headers});
  }
  
  updateComplexShippingAddress(shipping: Object) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/orders/updateshippingaddress`, shipping, {headers: headers});
  }
  getShippingAddressDetails(apartmentId: number ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/${apartmentId}/shippingaddress`, { headers: headers });
 }

  getComplexShippingAddressDetails(apartmentComplexId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/${apartmentComplexId}/locationshippingaddress`, { headers: headers });
  }

  updateCustomerpickUpAddress(apartmentID: number, value: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentID'] = apartmentID.toString();
    searchParams['value'] = value;
    const requestOptions = {headers, params: searchParams };
    return this.http.put(`${environment.api}api/complexes/customerpickup`, '', requestOptions);
  }

  getValidationImages() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/complexes/validations/images`, {headers: headers});
  }

  getComplexAddress( id: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get(`${environment.api}api/complexes/${id}/addresses`, {headers: headers});
  }

  validateCommunityName(complexId: string, complexName: string)  {
    const headers = new HttpHeaders();
  // tslint:disable-next-line:prefer-const
  headers.append('Content-Type', 'application/json');
  return this.http.get(`${environment.api}api/complexes/validateapartmentcomplex`, {headers, params: {complexName, complexId} });
}

getdefaulthingeoffset() {
  const headers = new HttpHeaders();
  return this.http.get(`${environment.api}api/complexes/getdefaulthingeoffset`, { headers: headers });
}

getUserType(): Observable<any> {
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.http.get(`${environment.api}api/account/iscustomerservice`, {headers});
}

  getCustomerContacts() {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/customercontact/getcustomercontacts`, { headers: headers });
  }

  getSalesRoles() {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/customercontact/getsalesroles`, { headers: headers });
  }

  getContacts() {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/contact/`, { headers: headers });
  }

  getCustomerContactsByCustomerContactId(contactid : number) {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/customercontact/${contactid}`, { headers: headers });
  }

  getCustomerContactsByCustomerId() {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/customercontact/bycustomerid`, { headers: headers });
  }

  getCustomerContactsByCustomerIdSalesRole(salesroleid : number) {
    const headers = new HttpHeaders();
    return this.http.get(`${environment.api}api/customercontact/bycustomerid/${salesroleid}`, { headers: headers });
  }

  saveContact(CustomerContact: any ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    //var returnID = this.http.post(`${environment.api}api/contact`, CustomerContact, { headers: headers });
   // CustomerContact.contactId = returnID;
    return  this.http.post(`${environment.api}api/contact`, CustomerContact, { headers: headers });
  }  
}
