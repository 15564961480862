import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Apartment } from '../types/apartment';
import { ReasonCode } from '../types/reason-code';
import { OrderEntryType, OrderEntryTypeType } from '../types/orderEntryType';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from 'rxjs/Observable';
import { Token } from '../types/token';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, timeout } from 'rxjs/operators';
import { MeasurementForm, MeasurementFormPost } from '../types/measurement-form';

@Injectable()
export class ApartmentService {
  public isSimilarOrder = new BehaviorSubject(false);
  private _orderEntryTypes: Array<OrderEntryType> = [];

  constructor(
    private http: HttpClient
  ) { }

  createapartment(apartment: Apartment) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments`, apartment, { headers: headers });
  }

  getApartmentDetails(id: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments`, { headers, params: { id: id } });
  }

  editApartment(apartment: Apartment): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments`, apartment, { headers: headers });
  }

  getReasonCodes(): Observable<ReasonCode[]> {
    return this.http.get<ReasonCode[]>(`${environment.api}api/apartments/reasonCodes`);
  }

  getReasonCodesByGroupId(id: number): Observable<ReasonCode[]> {
    return this.http.get<ReasonCode[]>(`${environment.api}api/apartments/reasonCodes/${id}`);
  }

  getReasonCodesByGroupName(groupName: string): Observable<ReasonCode[]> {
    return this.http.get<ReasonCode[]>(`${environment.api}api/apartments/reasonCodes/${groupName}`);
  }

  getPhraseSetting(): Observable<string> {
    return this.http.get<string>(`${environment.api}api/apartments/phraseSetting`);
  }


  editPoNumber(poNumber: string, apartmentsId: number[]): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${environment.api}api/apartments/editponumber?PONumber=${poNumber}`, apartmentsId, { headers: headers });
  }

  deleteaccesories(apartmentId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // tslint:disable-next-line:prefer-const
    let params: any = new Object();
    params['apartmentId'] = apartmentId.toString();
    const requestOptions = { headers, params };
    return this.http.delete(`${environment.api}api/apartments/deleteaccesories`, requestOptions);
  }

  removeaccesories(apartmentId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // tslint:disable-next-line:prefer-const
    let params: any = new Object();
    params['apartmentId'] = apartmentId.toString();
    const requestOptions = { headers, params };
    return this.http.delete(`${environment.api}api/apartments/removeaccesories`, requestOptions);
  }

  reorderSimilar(sourceAptID: Number, destinationAptID: Number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/ordersimilar?sourceAptID=${sourceAptID}&destinationAptID=${destinationAptID}`,
      {}, { headers: headers }).pipe(
        timeout(12000),
        catchError(err => {
          return Observable.throw('Timeout has occurred');
        })
      );
  }

  reorderSimilars(apartment: Apartment, sourceAptID: Number, quantity: Number, addToCart: boolean, orderEntryTypeType: string = null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['sourceAptID'] = sourceAptID;
    searchParams['quantity'] = quantity;
    searchParams['addToCart'] = addToCart;

    if (orderEntryTypeType && orderEntryTypeType != "")
      searchParams['orderEntryTypeId'] = this.getOrderEntryTypeId(orderEntryTypeType);

    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/apartments/ordersimilars`, apartment, requestOptions);
  }

  reorderSimilarAddingToCartWithoutReview(sourceAptID: Number, destinationAptID: Number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(
      `${environment.api}api/apartments/reorderSimilarAddingToCartWithoutReview?sourceAptID=${sourceAptID}&destinationAptID=${destinationAptID}`,
      {}, { headers: headers });
  }

  getShoppingCartAcknowledgment(cartGroup: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/acknowledgment`, { headers, params: { shoppingCartGroup: cartGroup } });
  }

  getShoppingCartQuote(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/orders/quote`, { headers });
  }

  calculateAptStandardItems(complexID: number, apartmentID: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/calculateaptstandarditems?complexID=${complexID}&apartmentID=${apartmentID}`, {}, { headers: headers });
  }

  deleteApartmentOrder(type: string, apartmentId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const search: string = type === 'apartments' ? 'apartmentId' : 'aptComplexID';
    // tslint:disable-next-line:prefer-const
    let params: any = new Object();
    params[search] = apartmentId.toString();
    const requestOptions = { headers, params };
    return this.http.delete(`${environment.api}api/${type}`, requestOptions);
  }

  AssignOrderToMe(apartmentsId: number[]): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/orders/assignordertome`, apartmentsId, { headers: headers });
  }

  SendCancelationMessage(apartmentsId: number[]) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/cancelationmessage/send`, apartmentsId, { headers: headers });
  }

  EditApartment(apartmentId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/editapartment?apartmentId=${apartmentId}`, {}, { headers: headers });
  }
  createGoogleSheet(apartmentId: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/customitems/generateSpreadSheet`, { headers, params: { apartmentID: apartmentId.toString() } });
  }

  createGoogleSheetToCommunity(complexID: number): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/customitems/generatespreadsheetnincommunity`, { headers, params: { apartmentID: complexID.toString() } });
  }
  uploadMeasurementForm(formData: FormData): Observable<any> {
    const params = new HttpParams();
    const options = {
      params,
      reportProgress: true,
    };
    return this.http.post(`${environment.api}api/measurementform/upload`, formData, options);
  }
  measurementFormCustomItem(apartmentId: number, data: Array<MeasurementForm>): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let measurementFormPost: MeasurementFormPost = new MeasurementFormPost(apartmentId,data);
    measurementFormPost.apartmentId = apartmentId;
    measurementFormPost.measurementFormList = data;
    return this.http.post<MeasurementFormPost>(`${environment.api}api/customitems/measurementformcustomitem`, measurementFormPost, { headers: headers });
  }
  bulkcustomitem(apartmentId: number, sheetId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['apartmentId'] = apartmentId.toString();
    searchParams['sheetId'] = sheetId;
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/customitems/bulkcustomitem`, '', requestOptions);
  }
  validateSpreadShet(apartmentId: number, sheetId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['complexId'] = apartmentId.toString();
    searchParams['sheetId'] = sheetId;
    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/customitems/validatecustomitemcommunity`, '', requestOptions);
  }

  bulkcustomitemcommunity(apartmentId: number, sheetId: string, orderEntryTypeType: string = null): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // tslint:disable-next-line:prefer-const
    let searchParams: any = new Object();
    searchParams['complexId'] = apartmentId.toString();
    searchParams['sheetId'] = sheetId;

    if (orderEntryTypeType && orderEntryTypeType != "")
      searchParams['orderEntryTypeId'] = this.getOrderEntryTypeId(orderEntryTypeType);

    const requestOptions = { headers, params: searchParams };
    return this.http.post(`${environment.api}api/customitems/bulkcustomitemcommunity`, '', requestOptions);
  }

  getDeliveryDateByApartments(body: Array<number>): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/apartments/deliverydatebyapartments`, body, { headers });
  }

  getOrderEntryTypes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    var reqHandler = this.http.get<Array<OrderEntryType>>(`${environment.api}api/orders/orderEntryTypes`, { headers })
      .subscribe({
        next: (res) => {
          this._orderEntryTypes = res;
        },
        complete: () => { reqHandler.unsubscribe(); }
      });
  }

  setOrderEntryType(apartmentId: number, orderEntryTypeType: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let orderEntryTypeId = this.getOrderEntryTypeId(orderEntryTypeType);
    let requestBody = { apartmentId, orderEntryTypeId };

    return this.http.post(`${environment.api}api/orders/setOrderEntryType`, requestBody, { headers });
  }

  getOrderEntryTypeId(orderEntryTypeType: string): string {
    let screenSize: string = "";
    let innerWidth = window.innerWidth;

    if (innerWidth >= 1200)
      screenSize = "LG";
    else if (innerWidth >= 992)
      screenSize = "MD";
    else if (innerWidth => 768)
      screenSize = "SM";
    else
      screenSize = "XS";

    let orderEntryType = this._orderEntryTypes.find(item => item.type == orderEntryTypeType
      && item.screenSize == screenSize);

    orderEntryType = orderEntryType || this._orderEntryTypes.find(item => item.type == OrderEntryTypeType.Unknown);

    return orderEntryType.id;
  }

  displayDeliveryDate(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/apartments/display-delivery-date`, { headers });
  }

  getUniqueUnit(apartment: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let requestBody = {
      SalesOrderNumber: apartment.orderNumber,
      ApartmentNumber: apartment.apartmentNumber
    };

    return this.http.post(`${environment.schedulingAPI}schedule/GetUniqueUnit`, requestBody, { headers });
  }

  updateStandardItemsForApartments(aptIds: number[]) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.api}api/updatestandarditems`; // Adjusted to match the new route

    // Send the array of apartment IDs as the request body
    return this.http.post<number[]>(url, aptIds, { headers: headers });
  }
}
