<div bsModal (onHidden)="emitHidden($event)" (onShown)="emitOpened($event)" (onHide)="emitHide($event)" #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-frame modal-dialog center">
    <div class="modal-content center" 
    [style.height]="isHeighter ? isHeighter : '573px'"
    [style.width]="isNarrow ? isNarrow : '920px'"
    [style.margin-top]="isHeighter ? '0px': '100px'">
      <div class="modal-header">
        <span (click)="hideChildModal()" aria-hidden="true">&times;</span>
      </div>
      <div class="modal-body">
        <ng-content select=".modal-body"> </ng-content>
      </div>
    </div>
  </div>
</div>
