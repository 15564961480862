import { HttpClient } from '@angular/common/http';
// Environment
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AlertService = /** @class */ (function () {
    function AlertService(http) {
        this.http = http;
    }
    AlertService.prototype.getAlertsByUser = function (customer, user) {
        return this.http.get(environment.api + "api/alerts/GetAlertsByUser", {
            params: {
                customerOwner: customer,
                userLogin: user
            }
        });
    };
    AlertService.prototype.putMarkReadAlert = function (model) {
        return this.http.put(environment.api + "api/alerts/MarkReadAlert", model);
    };
    AlertService.ngInjectableDef = i0.defineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.inject(i1.HttpClient)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
