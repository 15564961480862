import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PackagePlanningService = /** @class */ (function () {
    function PackagePlanningService(http) {
        this.http = http;
    }
    PackagePlanningService.prototype.process = function (item) {
        var url = environment.packagePlanningAPI + "/process";
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, item, httpOptions);
    };
    PackagePlanningService.ngInjectableDef = i0.defineInjectable({ factory: function PackagePlanningService_Factory() { return new PackagePlanningService(i0.inject(i1.HttpClient)); }, token: PackagePlanningService, providedIn: "root" });
    return PackagePlanningService;
}());
export { PackagePlanningService };
