import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var WIDTH_SMALL_DEVICES = 600;
var SharedService = /** @class */ (function () {
    function SharedService() {
        // Handle behavior
        this.sizeScreenHandler = new BehaviorSubject({});
        // Getting screen changes
        this.currentScreen = this.sizeScreenHandler.asObservable();
    }
    SharedService.prototype.onSaveScreenSizeChange = function (screenWidth, screenHeight) {
        this.sizeScreenHandler.next({
            width: screenWidth,
            height: screenHeight,
            isStandardDevice: screenWidth ? screenWidth > WIDTH_SMALL_DEVICES : true
        });
    };
    SharedService.ngInjectableDef = i0.defineInjectable({ factory: function SharedService_Factory() { return new SharedService(); }, token: SharedService, providedIn: "root" });
    return SharedService;
}());
export { SharedService };
